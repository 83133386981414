<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md6 my-auto>
        <h1>API de envío de notificaciones</h1>
      </v-flex>
    </v-layout>

    <v-card class="mt-8 mx-auto">
      <v-card-text>
        <v-card-title>
          <b>ENVIAR NOTIFICACIÓN A UN CANAL</b>
        </v-card-title>
        <v-card-title>
          POST: /api/notifications
        </v-card-title>
        <div class="ml-4 text--primary">
          <pre>
      {        
        "channel":"weather",
        "alert": {
            "title": "El tiempo en Madrid",
            "body": "Tiempo en Madrid para el Jueves"
        },
        "data": {
          "properties":[["date","Fecha"], ["temp","Temperatura"], ["weather","Tiempo"]],
          "date": "28/07/2021",
          "temp": "28 grados",
          "weather": "Soleado"
        }  
      }

      -------------------------------------------------------------------------------------

      {        
          "channel":"bpm",
          "data": {
            "properties":[["date","Fecha"],["state","Estado"],["priority","Prioridad"]],
            "title": "Incidencia BPM",
            "body": "Error en cálculo diario",
            "date": "18/08/2021",
            "state": "Abierta",
            "priority": "Alta"
          }  
      }
          </pre>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mt-8 mx-auto">
      <v-card-text>
        <v-card-title>
          <b>ENVIAR NOTIFICACIÓN A UN USUARIO</b>
        </v-card-title>
        <v-card-title>
          POST: /api/notifications
        </v-card-title>
        <div class="ml-4 text--primary">
          <pre>
      {
        "username":"username",       
        "alert": {
            "title": "Revisar error en fichaje diario",
            "body": "Es necesario que revise un descuadre en su fichaje diario"
        },
        "data": {
          "properties":[ ["type","Tipo incidencia"], ["day","Día"]],
          "type": "Descuadre",
          "day": "18/06/2021"
        } 
      }

      -------------------------------------------------------------------------------------

      {
        "username":"username",
        "data": {
            "properties": ["prioridad"],
            "title": "Notificación silenciosa",
            "body": "Este es una notificación con url y con un icono distinto",
            "prioridad": "Alta",
            "url": "https://www.emtmadrid.es",
            "icon": "https://findicons.com/files/icons/1773/free/256/person.png"
              }    
      }
          </pre>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  components: {},
  name: "Api",
  data: () => ({}),
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  methods: {},
};
</script>

<style scoped></style>
